import { render, staticRenderFns } from "./DiseaseDetail.vue?vue&type=template&id=33b46e86&scoped=true&"
import script from "./DiseaseDetail.vue?vue&type=script&lang=js&"
export * from "./DiseaseDetail.vue?vue&type=script&lang=js&"
import style0 from "./DiseaseDetail.vue?vue&type=style&index=0&id=33b46e86&prod&scoped=true&lang=css&"
import style1 from "./DiseaseDetail.vue?vue&type=style&index=1&id=33b46e86&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33b46e86",
  null
  
)

export default component.exports